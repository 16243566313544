/* global Component */
class priceComponent extends Component {

    static name() {
        return "priceComponent";
    }

    static componentName() {
        return "priceComponent";
    }

    data(){
        return {
            currentItemPriceData:null,
            itemCode:null,
        };
    }

    created(){
        return async function (){
            this.itemCode = this.item.Code;
        };
    }

    getProps() {
        return  ['item'];
    }

    getComputed(){
        return {
            itemPriceHideDecimals: function ()  {
                return this.$store.getters.getSetting.ItemPriceHideDecimals; 
            },
            forceDecimalSeparator: function () {
                return this.$store.getters.getSetting.FormatDecimalSeparator;
            },
            itemStore:function () {
                return this.$store.state.items_store.get(this.item.Code);
            },
            currentItemPrice:function () {
                const originalPrice = this.$store.getters.showItemPrice(this.itemStore);
                return this.formatNumber(originalPrice);
            },
            Currency:function () {
                return this.$store.getters.getCurrency;
            }
        };
    }

    getWatch() {
        return  {
            "itemStore.RowNet": function (newValue,oldValue) {
                this.updateItemPrice();
            },
            "itemStore.RowTotal": function (newValue,oldValue) {
                this.updateItemPrice();
            }
        };
    }

    getMethods() {
        return {
            updateItemPrice:this.updateItemPrice,
            formatNumber:this.formatNumber,
        };
    }

    formatNumber(price) {
        let formatPrice = parseFloat(new Number(price).toFixed(2));
        const lang = navigator.language || navigator.userLanguage;
        let maximumFractionDigits = 2;
        if(this.itemPriceHideDecimals) {
            formatPrice =  Math.trunc(formatPrice);
            maximumFractionDigits = 0;
        }
        if(this.forceDecimalSeparator)
            formatPrice =  formatPrice.toLocaleString(lang, {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits});
        return formatPrice;
    }

    updateItemPrice(){
        this.currentItemPriceData = this.$store.getters.showItemPrice(this.itemStore);
    }

    getTemplate() {
        return `<div class="price-container">
                <template v-if="currentItemPrice==null">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%"></div>
                    </div>
                </template>
                <template v-else>
                    <p class="card-text mb-2 item-price"> 
                        <strong v-if="item.CrossedOutPrice" class="crossedoutprice">$ {{formatNumber(item.CrossedOutPrice)}}</strong>
                        <strong v-if="currentItemPrice">$ {{currentItemPrice}} </strong>
                        <small v-if="Currency && currentItemPrice">{{Currency}}/{{item.Unit}}</small>
                        <small v-if="!Currency && currentItemPrice">{{item.Unit}}</small>
                    </p>
                </template>
            </div>`;
    }
}

priceComponent.registerComponent();